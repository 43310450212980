<template>
  <div class="opertation-log" style="margin: 0 24px">
    <section class="form-style">
      <TableForm
        :formRef="formRef"
        v-model:formData="formState"
        @searchList="searchList"
        @toDetail="toDetail"
        @reset="reset"
      />
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">日志列表</span>
        <div class="btn-block">
          <a-button @click="exportTable" v-btn="'PC_OPERATION_LOG_EXPORT'"
            ><i class="iconfont icon-daochu head-export" />导出</a-button
          >
        </div>
      </div>
      <a-table
        :columns="logColumns"
        :data-source="logData"
        :pagination="pagination"
        rowKey="id"
        :loading="tableLoading"
        @change="handleTanChange"
      >
        <template #idIndex="{ index }">{{ (pagination.current - 1) * pagination.pageSize + index + 1 }}</template>

        <template #operatorName="{ text }">
          <div v-if="!text || text.length <= 8">
            {{ text || '- -' }}
          </div>
          <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="lang">{{ text }}</div>
          </a-tooltip>
        </template>
        <template #departmentName="{ text }">
          <div v-if="!text || text.length <= 8">
            {{ text || '- -' }}
          </div>
          <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="lang">{{ text }}</div>
          </a-tooltip>
        </template>
        <template #content="{record, text }">
          <span
            v-if="text.length <= 30"
            :class="[record.details && 'text-link', 'content-style']"
            @click="toDetail(record)"
            >{{ text }}</span
          >
          <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <span :class="[record.details && 'text-link', 'content-style']" @click="toDetail(record)">{{ text }}</span>
          </a-tooltip>
        </template>
      </a-table>
    </section>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { logColumns } from '../columns'
import { useRouter } from 'vue-router'
import TableForm from './components/tableForm/index.vue'
import { useStore } from 'vuex'

import { getOperationLog, exportOperationLog } from '@/apis/businessManage'
import { paginationParams } from '@/utils/constData'
import moment from 'moment'
import { cmsNotice } from '@/utils/utils'
import { getEnums } from '@/apis/user'

export default {
  components: {
    TableForm
  },
  setup() {
    const state = reactive({
      logData: [],
      pagination: {
        ...paginationParams
      },
      formState: {
        operatorName: '',
        module: undefined,
        submitTime: [],
        searchContent: ''
      },
      tableLoading: false,
      formRef: null,
      logColumns
    })

    const router = useRouter()
    const store = useStore()

    //查询
    const searchList = () => {
      console.log(state.formState)
      state.pagination.current = 1
      getList()
    }

    const getModuleEnums = async () => {
      await getEnums({ kind: 'LOG_GENERATOR' }).then(res => {
        if (res.success) {
          console.log('res.data的值', res.data)
        }
      })
    }

    //重置
    const reset = () => {
      state.formState = { operatorName: '', module: undefined, submitTime: [], searchContent: '' }
      state.pagination.current = 1
      getList()
    }

    //详情
    const toDetail = record => {
      if (!record.details) return
      const module = record.module
      //type目前只有表单与流程设计有
      const type = record.type
      console.log(type, 'bread')
      const path = [43, 44].includes(type) ? '/operationLogDetail' : '/businessManage/operationLog/detail'
      store.commit('resetProcessData', { type: 2 })
      router.push({
        path,
        query: {
          type: type || module,
          id: record.id
        }
      })
    }

    //导出
    const exportTable = async () => {
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        searchContent: state.formState.searchContent,
        module: state.formState.module,
        operatorName: state.formState.operatorName,
        startTime: state.formState.submitTime.length
          ? `${moment(state.formState.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: state.formState.submitTime.length
          ? `${moment(state.formState.submitTime[1]).format('YYYY-MM-DD')} 00:00:00`
          : '' //结束时间
      }
      window.location = exportOperationLog(params)

      setTimeout(() => {
        cmsNotice('success', '正在为您导出，请耐心等待...')
      }, 200)
    }

    //获取列表
    const getList = async () => {
      state.tableLoading = true
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        searchContent: state.formState.searchContent,
        module: state.formState.module,
        operatorName: state.formState.operatorName,
        startTime: state.formState.submitTime.length
          ? `${moment(state.formState.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: state.formState.submitTime.length
          ? `${moment(state.formState.submitTime[1]).format('YYYY-MM-DD')} 00:00:00`
          : '' //结束时间
      }
      const { data, success, totalItem } = await getOperationLog(params)
      if (!success) return
      state.logData = data
      state.pagination.total = totalItem
      state.tableLoading = false
      console.log(store.state)
    }

    //分页
    const handleTanChange = ({ current, pageSize }) => {
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      getList()
    }

    onMounted(() => {
      getList()
      // getModuleEnums()
    })

    return {
      searchList,
      reset,
      toDetail,
      exportTable,
      handleTanChange,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
